import React from "react"
import './style.scss';

//import Layout from "../components/layout"
import Wrapper from '../templates/Wrapper/Wrapper';
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Wrapper>
    <SEO title="404: Not found" />

    <div className="blockwrap">
        <div className="container">
            <div className="content error-page">
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
        </div>
    </div>
    
  </Wrapper>
)

export default NotFoundPage
